<div class="banner-img">
    <img src="../../../assets/images/banner.png" />
  </div>
<div class="row dashboard-wrapper" style="background: white;">   
   
     <div class="col-3 col-md-2 desktop-wrapper">
       <span class="f-14 bborder cPointer" (click)="clear()">Clear All</span>
       <div>
         <section class="intrest filter-style">
            <div class="filter-title">Loan Amount</div>
            <div>
               <mat-card class="example-result-card">
                  <mat-card-content>
                    <div class="example-label-container">
                      <label id="example-name-label" class="example-name-label">₹ {{minAmount}}</label>
                      <label class="example-value-label">₹ {{maxAmount}}</label>
                    </div>
                    <mat-slider thumbLabel class="mySlider"
                    tickInterval="auto" 
                    min="{{minAmount}}" 
                    max="{{maxAmount}}" 
                    (input)="changeValue($event, 'loan_amount')"
                    
                    ></mat-slider>
                    
                  </mat-card-content>
                </mat-card>
            </div>               
         </section>
          <!-- <section class="purpose filter-style">
             <div class="filter-title">Purpose</div>
             <div class="devWithScroll">
                <p *ngFor="let item of purpose">
                   <mat-checkbox>{{item.name}}</mat-checkbox>
                </p>
             </div>               
          </section> -->
          <!-- <section class="product filter-style">
             <div class="filter-title">Product Category</div>
             <div class="devWithScroll">
                <p *ngFor="let item of product">
                   <mat-checkbox>{{item.name}}</mat-checkbox>
                </p>
             </div>               
          </section> -->
         <!-- <section class="grade filter-style">
             <div class="filter-title">Grade</div>
             <div class="devWithScroll">
                <p *ngFor="let item of grade">
                   <mat-checkbox (change)="gradeFilter($event, item)">{{item.name}}</mat-checkbox>
                </p>
             </div>               
          </section> -->
          <section class="intrest filter-style">
             <div class="filter-title">Interest Rate (%)</div>
             <div>
                <mat-card class="example-result-card">
                   <mat-card-content>
                     <div class="example-label-container">
                       <label id="example-name-label" class="example-name-label">{{min}}</label>
                       <label class="example-value-label">{{max}}</label>
                     </div>
                     <mat-slider thumbLabel class="mySlider"
                     tickInterval="auto" 
                     min="{{min}}" 
                     max="{{max}}" 
                     (input)="changeValue($event, 'intrest_rate')"
                     ></mat-slider>
                     <!-- <mat-slider
                     class="example-margin"
                     [disabled]="disabled"
                     [max]="max"
                     [min]="min"
                     [step]="step"
                     [discrete]="thumbLabel"
                     [showTickMarks]="showTicks">
                   <input matSliderThumb [(ngModel)]="value">
                 </mat-slider> -->
                     <!-- <mat-slider
                     class="example-margin"
                     [disabled]="disabled"
                     [max]="max"
                     [min]="min"
                     [step]="step"
                    
                     >
                   <input matSliderThumb [(ngModel)]="value">
                 </mat-slider> -->
                   </mat-card-content>
                 </mat-card>
             </div>               
          </section>
          <section class="intrest filter-style">
             <div class="filter-title">Tenure (In Month)</div>
             <div>
                <mat-card class="example-result-card">
                   <mat-card-content>
                     <div class="example-label-container">
                       <label id="example-name-label" class="example-name-label">{{minMonth}}</label>
                       <label class="example-value-label">{{maxMonth}}</label>
                     </div>
                     <mat-slider thumbLabel class="mySlider"
                     tickInterval="auto" 
                     min="{{minMonth}}" 
                     max="{{maxMonth}}" 
                     (input)="changeValue($event, 'tenure')"
                     ></mat-slider>
                     <!-- <mat-slider
                     class="example-margin"
                     [disabled]="disabled"
                     [max]="maxMonth"
                     [min]="minMonth"
                     [step]="stepMonth"
                     [discrete]="thumbLabelMonth"
                     [showTickMarks]="showTicksMonth">
                   <input matSliderThumb [(ngModel)]="valueMonth">
                 </mat-slider> -->
                   </mat-card-content>
                 </mat-card>
             </div>               
          </section>
         <!-- <section class="gender filter-style">
             <div class="filter-title">Product</div>
             <div>
                <p *ngFor="let item of productMonth">
                   <mat-checkbox>{{item.name}} Months</mat-checkbox>
                </p>
             </div>                
             <div class="mt-10">
               <mat-radio-group >
                  <mat-radio-button *ngFor="let item of productMonth" [value]="item.name" (change)="onRadioChange($event)">{{item.name}} Months</mat-radio-button>
                </mat-radio-group>
             </div>
          </section>-->
          <!-- <section class="gender filter-style">
             <div class="filter-title">Gender</div>
             <div>
                <p *ngFor="let item of gender">
                   <mat-checkbox>{{item.name}}</mat-checkbox>
                </p>
             </div>               
          </section> -->
          <!-- <section class="income filter-style">
             <div class="filter-title">Age(Years)</div>
             <div>
                <mat-card class="example-result-card">
                   <mat-card-content>
                     <div class="example-label-container">
                       <label id="example-name-label" class="example-name-label">{{minAge}}</label>
                       <label class="example-value-label">{{maxAge}}</label>
                     </div>
                     <mat-slider
                         class="example-margin"
                         [disabled]="disabled"
                         [max]="maxAge"
                         [min]="minAge"
                         [step]="stepAge"
                         [discrete]="thumbLabelAge"
                         [showTickMarks]="showTicksAge">
                       <input matSliderThumb [(ngModel)]="valueAge">
                     </mat-slider>
                   </mat-card-content>
                 </mat-card>
             </div>               
          </section> -->
          <!-- <section class="emp filter-style">
             <div class="filter-title">Employment Type</div>
             <div>
                <p *ngFor="let item of emp">
                   <mat-checkbox>{{item.name}}</mat-checkbox>
                </p>
             </div>               
          </section> -->
          <!-- <section class="housing filter-style">
             <div class="filter-title">Housing Type</div>
             <div>
                <p *ngFor="let item of housing">
                   <mat-checkbox>{{item.name}}</mat-checkbox>
                </p>
             </div>               
          </section> -->
          <!-- <section class="income filter-style">
             <div class="filter-title">Monthly Income</div>
             <div>
                <mat-card class="example-result-card">
                   <mat-card-content>
                     <div class="example-label-container">
                       <label id="example-name-label" class="example-name-label">{{minIncome}}</label>
                       <label class="example-value-label">{{maxIncome}}</label>
                     </div>
                     <mat-slider
                         class="example-margin"
                         [disabled]="disabled"
                         [max]="maxIncome"
                         [min]="minIncome"
                         [step]="stepIncome"
                         [discrete]="thumbLabelIncome"
                         [showTickMarks]="showTicksIncome">
                       <input matSliderThumb [(ngModel)]="valueIncome">
                     </mat-slider>
                   </mat-card-content>
                 </mat-card>
             </div>               
          </section> -->
          <!-- <section class="income filter-style">
             <div class="filter-title">Funded(%)</div>
             <div>
                <mat-card class="example-result-card">
                   <mat-card-content>
                     <div class="example-label-container">
                       <label id="example-name-label" class="example-name-label">{{minFun}}</label>
                       <label class="example-value-label">{{maxFun}}</label>
                     </div>
                     <mat-slider
                         class="example-margin"
                         [disabled]="disabled"
                         [max]="maxFun"
                         [min]="minFun"
                         [step]="stepFun"
                         [discrete]="thumbLabelFun"
                         [showTickMarks]="showTicksFun">
                       <input matSliderThumb [(ngModel)]="valueFun">
                     </mat-slider>
                   </mat-card-content>
                 </mat-card>
             </div>               
          </section> -->
       </div>
    </div>
    <div class="col-12 col-md-10">
       <div class="row borderAdd addBorderBottom">
          <div class="col-4 col-md-4 m-auto">
             Showing {{count}} of {{count}}
          </div>
         
          <div class="col-8 col-md-8">
            <div class="filter" (click)="openFilter()"  matBadge="4" matBadgeOverlap="true">
               <img src="../../../assets/front/img/icons/login/filter.png" />
            </div>
             <div class="filter-sec"> 
                <div class="sort_field">
                   Sort By
                </div>
                <div class="selectDiv">
                    <mat-form-field>
                        <mat-select placeholder="Select">
                          <mat-option *ngFor="let item of selectOption" [value]="item.value" (click)="sort(item.viewValue)">
                            {{ item.viewValue }}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
             </div>
            
          </div>
       </div>
       <div class="row cart-wrapper">
          <div class="col-md-4" *ngFor="let item of filterData">
             <div class="cart">
                <div class="c-header">
                   <div class="loan-no cPointer" [ngClass]="{'disableClass': item.funding_status === 100}" (click)="openDetails(item.application_id)">{{item.borrower_id}}</div>
                   <div [ngClass]="{'disableClass': item.funding_status === 100}" class="loan-type cPointer">
                     <!-- <div (click)="openDetails(item.application_id)"> -->
                        <div>
                        Personal Loan
                     </div>
                   </div>
                </div>  
                <div class="c-content">
                   <div class="row">
                      <div class="col-6 col-md-6">
                         <div class="textLabel greyColorLabel">
                            Loan Amount
                         </div>
                         <div class="textLabel DgreyColorLabel fw-600">₹ {{item.loan_amount | number}}</div>
                      </div>
                      <div class="col-6 col-md-6">
                         <div class="textLabel greyColorLabel">
                            Left Loan Amount
                         </div>
                         <div class="textLabel DgreyColorLabel fw-600">₹ {{item.available_amount | number}}</div>
                      </div>
                   </div>
                   <div class="row mt-20">
                      <div class="greyColorLabel col-md-9 f-14">Funded</div>
                      <div class="DgreyColorLabel fw-600 col-md-3 text-end">{{(((item?.loan_amount - item?.available_amount)/item?.loan_amount)*100).toFixed(2) | number : '1.0' }}%</div>
                   </div>
                   <div class="row mt-10">
                     
                        <mat-progress-bar mode="determinate" [value]="(((item?.loan_amount - item?.available_amount)/item?.loan_amount)*100).toFixed(2)"></mat-progress-bar>
                 
                     <!-- <mat-progress-bar mode="determinate" value="40"></mat-progress-bar> -->
                      <!-- <mat-progress-bar mode="determinate" value="40" color="warn"></mat-progress-bar> -->
                   </div>
                </div> 
                <div class="bottom-content">
                   <div class="col-4 col-md-4">
                      <div class="f-13 greyColorLabel">Interest Rate</div>
                      <div class="f-13 DgreyColorLabel fw-600 mt-10">{{item.irr}}</div>
                   </div>
                   <div class="col-4 col-md-4">
                      <div class="f-13 greyColorLabel">Tenure</div>
                      <div class="f-13 DgreyColorLabel fw-600 mt-10">{{item.net_tenure}} (Month)</div>
                   </div>
                   <div class="col-4 col-md-4">
                      <div class="f-13 greyColorLabel">Category</div>
                      <div class="f-13 DgreyColorLabel fw-600 mt-10">{{item.purpose}}</div>
                   </div>
                </div>    
                <!-- <div class="text-center mt-15">
                   <button mat-raised-button color="primary" (click)="openDetails(item.application_id)" [disabled]="(item.funding_status==100)">Invest</button>
                </div>        -->
             </div>
          </div>         
       </div>
    </div>
 </div>
 <!-- <div class="row mobile-Wrapper">
   <div class="col-md-6 cPointer">Sort</div>
   <div class="col-md-6 cPointer">Filter</div>
</div> -->

<div *ngIf="isopenFilter" class="filterStyle">
   <div class="row dashboard-wrapper filter-box">
      <div class="col-10 col-md-10 m-auto filterContainer">
         <div class="close-icon" (click)="close()">
            <mat-icon mat-dialog-close>close</mat-icon>
         </div>
         <div class="row">
            <span class="f-14 bborder fw-900 cPointer" style="text-align: right;">Clear All</span>
         </div>
         
         <div>
            <section class="loan-type filter-style">
               <div class="filter-title">Loan Type</div>
               <div>
                  <p *ngFor="let item of loanType">
                     <mat-checkbox>{{item.name}}</mat-checkbox>
                  </p>
               </div>               
            </section>
            <section class="product filter-style">
               <div class="filter-title">Product Category</div>
               <div class="devWithScroll">
                  <p *ngFor="let item of product">
                     <mat-checkbox>{{item.name}}</mat-checkbox>
                  </p>
               </div>               
            </section>
            <section class="grade filter-style">
               <div class="filter-title">Grade</div>
               <div class="devWithScroll">
                  <p *ngFor="let item of grade">
                     <mat-checkbox>{{item.name}}</mat-checkbox>
                  </p>
               </div>               
            </section>
            <section class="intrest filter-style">
               <div class="filter-title">Interest Rate (%)</div>
               <div>
                  <mat-card class="example-result-card">
                     <mat-card-content>
                       <div class="example-label-container">
                         <label id="example-name-label" class="example-name-label">{{min}}</label>
                         <label class="example-value-label">{{max}}</label>
                       </div>
                       <!-- <mat-slider
                       class="example-margin"
                       [disabled]="disabled"
                       [max]="max"
                       [min]="min"
                       [step]="step"
                       [discrete]="thumbLabel"
                       [showTickMarks]="showTicks">
                     <input matSliderThumb [(ngModel)]="value">
                   </mat-slider> -->
                       <!-- <mat-slider
                           class="example-margin"
                           [disabled]="disabled"
                           [max]="max"
                           [min]="min"
                           [step]="step"
                           >
                         <input matSliderThumb [(ngModel)]="value">
                       </mat-slider> -->
                     </mat-card-content>
                   </mat-card>
               </div>               
            </section>
            <section class="intrest filter-style">
               <div class="filter-title">Tenure (In Month)</div>
               <div>
                  <mat-card class="example-result-card">
                     <mat-card-content>
                       <div class="example-label-container">
                         <label id="example-name-label" class="example-name-label">{{minMonth}}</label>
                         <label class="example-value-label">{{maxMonth}}</label>
                       </div>
                       <!-- <mat-slider
                       class="example-margin"
                       [disabled]="disabled"
                       [max]="maxMonth"
                       [min]="minMonth"
                       [step]="stepMonth"
                       [discrete]="thumbLabelMonth"
                       [showTickMarks]="showTicksMonth">
                     <input matSliderThumb [(ngModel)]="valueMonth">
                   </mat-slider> -->
                       <!-- <mat-slider
                           class="example-margin"
                           [disabled]="disabled"
                           [max]="maxMonth"
                           [min]="minMonth"
                           [step]="stepMonth"
                          >
                         <input matSliderThumb [(ngModel)]="valueMonth">
                       </mat-slider> -->
                     </mat-card-content>
                   </mat-card>
               </div>               
            </section>
            <section class="emp filter-style">
               <div class="filter-title">Employment Type</div>
               <div>
                  <p *ngFor="let item of emp">
                     <mat-checkbox>{{item.name}}</mat-checkbox>
                  </p>
               </div>               
            </section>
           
         </div>
      </div>
</div>
import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmationComponent implements OnInit {

  constructor(private sharedService : SharedService, private router : ActivatedRoute, private route : Router) { }

  ngOnInit(): void {
      const razorpay_payment_id = this.router.snapshot.queryParams['razorpay_payment_id']; 
      const razorpay_payment_link_id = this.router.snapshot.queryParams['razorpay_payment_link_id'];
      const razorpay_payment_link_reference_id= this.router.snapshot.queryParams['razorpay_payment_link_reference_id']; 
      const razorpay_payment_link_status= this.router.snapshot.queryParams['razorpay_payment_link_status'];
      const razorpay_signature= this.router.snapshot.queryParams['razorpay_signature'];
      const promise = this.sharedService.paymentConfirmation(razorpay_payment_id, razorpay_payment_link_id, razorpay_payment_link_reference_id,
        razorpay_payment_link_status, razorpay_signature );
       promise.then(
        (res:any)=>{
          if(res.status=="OK"){
            this.route.navigateByUrl('dashboard/invest/success')
          }
          else{
            this.route.navigateByUrl('dashboard/invest/failure')
          }
        }
      )
      .catch((err : any) => {
        this.route.navigateByUrl('dashboard/invest/failure')
      }
  
      )
    
    
  }

}

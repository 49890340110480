import { Component, OnInit } from '@angular/core';
import { CryptoService } from 'src/app/services/crypto.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Hashids from "hashids";
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { PopupMessageComponent } from 'src/app/modules/navigation/popup-message/popup-message.component';
import { Constants } from 'src/app/common/constants/constants';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  isValid: boolean = false;
  id: any;
  dateTime: any;
  form!: FormGroup;
  timeout: any = Constants.POPUP_TIMEOUT;
  showInvalidResetLink: boolean = false;
  formPWValue: any;
  convertPassword: any;
  buildKey: any;
  getdata: any;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog) {
    this.route.queryParams.subscribe((parmas: any) => {
      this.id = parmas['id'] || null;
      this.dateTime = parmas['dt'] || null;
    });

    this.authService.loginToken()
      .pipe(first())
      .subscribe(
        (data: HttpResponse<any>) => {
          this.getdata = data.headers?.get('cfc-token');
        })
  }

  ngOnInit(): void {
    this.isValid = true;
    if (this.dateTime) {
      let hashids = new Hashids(environment.key);
      let dateTime = hashids.decode(this.dateTime);
      let d = dateTime.length > 0 ? dateTime[0] : 0;
      let t = new Date(Number(d) * 1000).getTime();
      let currentDateTime: any = new Date();
      // if ((((currentDateTime.getTime() - t) / 1000) / 60) > 15) {
      //   this.isValid = false;
      //   this.showInvalidResetLink = true;
      //   // const popup = this.dialog.open(InvalidResetLinkComponent)
      // }
    }

    this.form = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.pattern(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/
      ), Validators.minLength(6), NoWhitespaceValidator()]),
      confirmPassword: new FormControl('', [Validators.required])
    }, {
      validator: ConfirmPasswordValidator('password', 'confirmPassword')
    }
    );
  }

  get resetForm() {
    return this.form.controls;
  }

  submit() {
    if (this.form.valid) {
      this.formPWValue = this.form.value.password;
      var key = CryptoJS.enc.Utf8.parse(this.getdata);
      var iv = CryptoJS.enc.Utf8.parse(this.getdata);
      this.convertPassword = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(this.formPWValue.trim().toString()),
        key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
        }
      );
      this.buildKey = this.convertPassword.toString();

      let type = 1
      if (window.location.href.includes('ifa')) {
        type = 3
      }
      const data = {
        "id": this.id,
        "datetime": this.dateTime,
        "password": this.buildKey,
        "type": type
      }
      const promise = this.authService.resetPassword(data);
      promise.then((res: any) => {
        if (res.status == "OK") {
          const popup = this.dialog.open(PopupMessageComponent, {
            data: {
              title: "success",
              message: res.message
            }
          });
          popup.afterOpened().subscribe((res: any) => {
            setTimeout(() => {
              popup.close();

            }, this.timeout)
          })
          popup.afterClosed().subscribe((res: any) => {
            this.router.navigateByUrl('/auth/login')
          })
          this.form.reset();
        }
      })
        .catch((error: any) => {
          const popup = this.dialog.open(PopupMessageComponent, {
            data: {
              title: "Error",
              message: error.error.message
            }
          })
          popup.afterOpened().subscribe((res: any) => {
            setTimeout(() => {
              popup.close();
            }, this.timeout)
          })
        })
    }
  }

}

export function ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    // if (matchingControl.errors) {
    //     return;
    // }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

export function NoWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): any => {
    window.setTimeout(() => {
      if (control.value && control.value != '') {
        let trimedvalue = control.value.replace(/\s/g, '');
        control.setValue(trimedvalue);
      }
    }, 10);
  };
}

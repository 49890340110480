<div class="footer-wrapper pb-50" style="background: black;">
    <div class="container pt-100" style="max-width:100%; padding: 30px 30px 30px 30px;">
        <div class="row m-auto white-text">
            <div class="col-md-4">
                <ul class="f-15">
                    <span class="yellow-text f-20 boldfont fw-500">Contact Details</span>
                    <li class="mt-20">
                        Address :  Surya Mahal 4th Floor, 5 Burjorji Bharucha Marg,<br />
                        Mumbai-400001.
                    </li>
                    <li>Working Hours :  Mon to Fri from 9 a.m. to 5 p.m.</li>
                    <li>Phone :  <a href="tel:8097400463"></a> +91 8097400463</li>
                    <li>Email :  <a href="mailto:info@creditfaircapital.in">info@creditfaircapital.in</a></li>
                </ul>
            </div>

            <div class="col-md-2">
                <ul class="f-15">
                    <span class="yellow-text f-20 boldfont fw-500">Quick Links</span>
                    <p class="mt-20"><a href="/"><i class="fa-solid fa-chevron-right" style="color:orange"></i>  Home</a></p>
                    <p><a href="about"> <i class="fa-solid fa-chevron-right" style="color:orange"></i>  About Us</a></p>
                    <p><a href="loans"> <i class="fa-solid fa-chevron-right" style="color:orange"></i>  Loans</a></p>
                    <p><a href="investment"> <i class="fa-solid fa-chevron-right" style="color:orange"></i>  Investment</a></p>
                </ul>
            </div>
            <div class="col-md-2">
                <ul class="f-15">
                    <span class="yellow-text f-20 boldfont fw-500">Important Links</span>
                    <p class="mt-20"><a href="broad-business-model"> <i class="fa-solid fa-chevron-right" style="color:orange"></i>  Broad Business Model</a></p>
                    <p><a href="grievence-redressal-policy"> <i class="fa-solid fa-chevron-right" style="color:orange"></i>  Grievance Redressal Policy</a></p>
                    <p><a href="credit-assesment"> <i class="fa-solid fa-chevron-right" style="color:orange"></i>  Credit Assesment</a></p>
                    <p><a href="privacy-policy"><i class="fa-solid fa-chevron-right" style="color:orange"></i>  Privacy Policy</a></p>
                </ul>
            </div>
            <div class="col-md-2">
                <ul class="f-15">
                    <span class="yellow-text f-20 boldfont fw-500 "></span>
                    <p style="margin-top: 40px !important;"><a href="terms-and-conditions"><i class="fa-solid fa-chevron-right" style="color:orange"></i> Terms of Use</a></p>
                    <p><a href="kyc-policy"><i class="fa-solid fa-chevron-right" style="color:orange"></i> KYC Policy</a></p>
                    <p><a href="fair-practice-code"><i class="fa-solid fa-chevron-right" style="color:orange"></i> Fair Practice Code</a></p>
                    <p><a href="portfolio-performance"><i class="fa-solid fa-chevron-right" style="color:orange"></i> Portfolio Performance</a></p>
                    <p><a href="outsourcing-policy"><i class="fa-solid fa-chevron-right" style="color:orange"></i> Outsourcing Policy</a></p>
                </ul>
            </div>
            <div class="col-md-2">
                <ul class="f-15 socialmedia">
                    <span class="yellow-text f-20 boldfont fw-500">Our Social Networks</span>
                    <a href="https://www.facebook.com/creditfairindia" class="mt-20 fa-brands fa-facebook"></a>
                    <a href="https://www.instagram.com/credit_fair/" class="mt-20 fa-brands fa-instagram"></a>
                    <a href="https://www.linkedin.com/company/credit-fair" class="mt-20 fa-brands fa-linkedin"></a>
                </ul>
            </div>
        </div>

        <div class="row m-auto white-text">
            <span class="yellow-text f-20 boldfont fw-500">Disclaimer</span>
            <p class="mt-20">"The company is having a valid certificate of Registration dated 10-01-2024 issued by Reserve Bank Of India under section 45 IAof the Reserve Bank Of India Act, 1934. However, the Reserve Bank of India does not accept any responsibility or guarantee about the present position as to the financial soundness of the company or for the correctness of any of the statements or representations made or opinions expressed by the company and for the repayment of deposits/discharge of liabilities by the company.</p>
        </div>

        <div class="row m-auto f-15 mt-10 white-text">
            <p><strong>Copyright &copy; {{copyrightYear}} Credit Fair Capital is the brand name of K.M. Global P2P Finance Pvt. Ltd, an NBFC registered with the RBI. </strong></p>
        </div>
    </div>
</div>

export class Constants {
    public static readonly MINIMUM_AMOUNT = 10000;
    public static readonly WITHDRAW_MINIMUM_AMOUNT = 1000;
    public static readonly MAXIMUM_AMOUNT = 5000000;
    public static readonly POPUP_TIMEOUT = 10000;
    static CURRENT_TIME: any;
    public readonly CURRENT_TIME = this.formatTime(new Date());
    constructor() {
        this.CURRENT_TIME = this.formatTime(new Date());
    }
    formatTime(date: Date): string {
        const formattedtime = `${date.getFullYear()}-${this.padZero(date.getMonth() + 1)}-${this.padZero(date.getDate())} ${this.padZero(date.getHours())}:${this.padZero(date.getMinutes())}:${this.padZero(date.getSeconds())}`;
        return formattedtime;
    }

    padZero(num: number): string {
        return num < 10 ? '0' + num : num.toString();
    }
}
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { interval } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { Subscriber } from 'rxjs/internal/Subscriber';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Logout } from 'src/app/store/actions/logout.action';
import { CookieService } from 'src/app/services/cookie.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

	private GENERATE_OTP = 'service/generate-otp';
	private GENERATE_EMAIL_OTP = 'service/generate-email-otp';
	private VERIFY_OTP = 'service/verify-otp';
	private VERIFY_EMAIL_OTP = 'service/verify-email-otp';
	private LOGIN = 'service/verify-password';
	private FORGOT_PASSWORD = 'service/forgot-password';
	private RESET_PASSWORD = 'service/submit-password';
	private USER_INFO = 'api/get-user';
	private FETCH_TOKEN = '';
	private LOGIN_TOKEN = 'api/login-token';
	private LOGOUT = 'api/investor/logout-investor';

	private Token: string = '';
	private Access_Token: string = '';
	private readonly REFRESH_TOKEN: string = 'refreshToken';
	private User: any;
	onUserUpdate: Subject<any> = new Subject();
	showOTP: any = new Subject<any>();

	private options = {
		headers: new HttpHeaders()
			.set('Content-Type', 'application/json')
	};

	HTTPOptionsForText: Object = {
		headers: new HttpHeaders({'Content-Type': 'application/json'}),
		responseType: 'text'
	}

	constructor(
		private http: HttpClient,
		private store: Store,
		private router: Router,
		private cookieService: CookieService) { }

	generateOTP(mobileOrEmail: string, type: any,) {
		const data = {
			phone: mobileOrEmail,
			type: 3
		}
		return this.http.post(environment.base_url + this.GENERATE_OTP, data, this.options);
	}

	verifyOTP(mobileOrEmail: string, otp: string, type: any, ref_code: any,category : any) {
		const data = {
			phone: mobileOrEmail,
			type: 3,
			otp: Number(otp),
			referred_by: ref_code,
			category : category
		}
		return this.http.post(environment.base_url + this.VERIFY_OTP, data, this.options);
	}

	loginUser(data: any) {
		return this.http.post(environment.base_url + this.LOGIN, data, this.options);
	}

	loginUser1(data: any) {
		return this.http.post(environment.base_url + this.LOGIN, data, this.options);
	}

	fetchUser() {
		return this.http.get(`${environment.base_url}${this.USER_INFO}`, this.options);
	}

	forgotPassword(data: any) {
		return this.http.post(environment.base_url + this.FORGOT_PASSWORD, data, this.options).toPromise();
	}

	resetPassword(data: any) {
		return this.http.post(environment.base_url + this.RESET_PASSWORD, data, this.options).toPromise();
	}

	public get user() {
		return this.User;
	}

	public setUser(user: any) {
		this.User = user;
		this.onUserUpdate.next(user);
	}

	public get token() {
		return this.Token;
	}

	public setTokens(data: any) {
		this.Token = data.IdToken;
		this.Access_Token = data.AccessToken
		localStorage.setItem(
			'refreshToken',
			data.RefreshToken
		)
	}

	getTokens(refresh_token: string): Observable<any> {
		const headers = { 'Authorization': this.Token };
		const data = {
			refresh_token: refresh_token
		}
		return this.http.post(environment.base_url + this.FETCH_TOKEN, data, { headers });
	}

	public refreshToken(minutes: number) {
		return new Observable<any>(
			(subscriber: Subscriber<any>) => {
				interval(1000 * 60 * minutes).subscribe((x: any) => {
					const refreshToken: any = localStorage.getItem(this.REFRESH_TOKEN);
					this.getTokens(refreshToken).subscribe((response: any) => {
						subscriber.next(response);
					});
				});
			}
		);
	}

	public startTokenUpdateTimer(time: number) {
		this.refreshToken(time).subscribe((data: any) => {
			this.setTokens(data);
		});
	}

	logoutUser() {
		this.store.dispatch(new Logout());
		this.cookieService.deleteCookie('token')
		localStorage.clear();
		this.router.navigateByUrl('/auth/login');
	}

	loginToken(): Observable<any> {
		return this.http.get<any>(environment.borrower_baseUrl + this.LOGIN_TOKEN, { observe: 'response' });
	}
	
	logout(data: any) {
		return this.http.post(environment.borrower_baseUrl + this.LOGOUT, data);
	}

	generateEmailOTP(mobileOrEmail: string, type: any) {
		const data = {
			email: mobileOrEmail,
			type: type
		}
		return this.http.post(environment.base_url + this.GENERATE_EMAIL_OTP, data, this.options);
	}

	verifyEmailOTP(mobileOrEmail: string, otp: string, type: any, ref_code: any,category: any) {
		const data = {
			email: mobileOrEmail,
			type: type,
			otp: Number(otp),
			referred_by: ref_code,
			category : category
		}
		return this.http.post(environment.base_url + this.VERIFY_EMAIL_OTP, data, this.options);
	}
}

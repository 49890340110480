import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { CookieService } from 'src/app/services/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
    private cookieService: CookieService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isSameDomainRoute: boolean = true;
    if (state.url !== '/onboarding') {
      isSameDomainRoute = window.location.href.includes('ifa') && state.url.includes('ifa') ? true
        : !window.location.href.includes('ifa') && !state.url.includes('ifa') ? true : false;
    }
    const token = this.cookieService.getCookie('token');//localStorage.getItem('token');
    if (token && token !== '' && isSameDomainRoute) {
      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigateByUrl('auth/login');
    return false;
  }

}

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Logout } from 'src/app/store/actions/logout.action';
import { UtilsService } from 'src/app/services/utils.service';
import { SharedService } from '../services/shared.service';
import { CookieService } from 'src/app/services/cookie.service';
import { CryptoService } from 'src/app/services/crypto.service';
import {Clipboard} from '@angular/cdk/clipboard'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  hideElement = true;
  hideOnboard = true;
  hideInvestorButton : boolean = true;
  hideInvestNowButton : boolean = true;
  username : string = '';
  currentURL: any = '';
  hideGoToDashboardButton: boolean = true;
  isOnboarding: boolean = false;
  isLogin: boolean = false;
  investor_id:any;
  copyTooltip: string = 'Copy Referral Link';

  constructor(private router : Router,
    private activatedRoute: ActivatedRoute, 
    private store: Store,
    private utilsService : UtilsService, private sharedService : SharedService,
    private cookieService : CookieService, private cryptoService:CryptoService,
    private clipboard: Clipboard) {
    this.router.events.subscribe((event) => {
      let routes = ['/', '/auth/login', '/blank', '/privacy-policy', '/tnc', '/reset-password']
      if (event instanceof NavigationEnd) {
        console.log(event.url)
        this.hideElement = (routes.includes(event.url) || event.url.includes('/reset-password')) ? true : false
        if (event.url.includes('/auth/login') || event.url.includes('/login') || event.url.includes('/reset-password')) {
          this.hideElement = false;
          this.isLogin = true;
          this.isOnboarding = false;
          this.hideInvestNowButton = true;
          this.hideOnboard = true;
        } else if (event.url.includes('/onboarding')) {
          this.hideElement = false;
          this.isOnboarding = true;
          this.isLogin = false;
          this.hideInvestNowButton = true;
          this.hideOnboard = true;
        } else {
          this.isLogin = false;
          this.isOnboarding = false;
          this.hideInvestNowButton = false;
          this.hideOnboard = false;
        }

        if (event.url.includes('p2pdashboard')) {
          this.hideGoToDashboardButton = false;
          this.hideInvestNowButton = true;
        } else {
          this.hideGoToDashboardButton = true;
        }

        let reloadBlank = localStorage.getItem('reloadBlank')
        console.log(reloadBlank)
        if(reloadBlank == 'true'){
          localStorage.setItem('reloadBlank','false')
          window.location.reload();
        }
      }
    })
  }

  ngOnInit(): void {   
    this.activatedRoute.queryParams.subscribe(params => {
      this.investor_id = params['id'];
    })

    this.utilsService.onUserUpdate.subscribe((data : any) => {
      this.username = data.username;
      this.currentURL = data.referral_link;
    });

    if(this.username == ''){
      this.username = localStorage.getItem('username') || '';
    }

    if(this.currentURL == ''){
      let refUrl = localStorage.getItem('referral_link') || '';      
      this.currentURL = refUrl ? this.cryptoService.encrypt(refUrl) : refUrl;
    }
  }

  logout(){
    this.store.dispatch(new Logout());    
    this.cookieService.deleteCookie('token')
    localStorage.clear();
    this.router.navigateByUrl('/auth/login')
  }

  addInvestor(){
    const url = this.currentURL; 
    window.open(url, '_blank');
    this.sharedService.accordianOpenHandler.next(true);
  }
  
  investNow(){
    this.router.navigateByUrl('/ifa/dashboard/invest/');
  }

  ifaDashboard(){
    this.router.navigateByUrl('/ifa/dashboard');
  }

  investorOnboard(){
    if(this.investor_id){
      localStorage.setItem('reloadBlank','true')
    }
    this.router.navigateByUrl('/ifa/dashboard/investor-onboarding');
  } 
  
  copy_file() {
    this.copyTooltip = 'Copied Referral Link';
    this.clipboard.copy(this.currentURL);
  }
}

import { Dialog } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-message',
  templateUrl: './popup-message.component.html',
  styleUrls: ['./popup-message.component.scss']
})
export class PopupMessageComponent implements OnInit {
  wSuccess:boolean=false;
  iSuccess:boolean=false;
  iFailure:boolean = false;
  error: boolean = false;
  nomSuccess:boolean=false;
  nomFailure:boolean = false;
  neftFailure:boolean=false;
  investorSuccess: boolean = false;
  accountisNotfetched : boolean = false;
  borrowersAllocated : boolean = false;
  title !:string;
  agreementSend : boolean =false;
  constructor(
    public dialogRef:MatDialogRef<PopupMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{title:string, message:string}) { }

  ngOnInit(): void {
    if (this.data.title === "WSuccess"){
      this.wSuccess = true;
      this.iSuccess = false;
      this.iFailure = false;
      this.nomSuccess = false;
      this.investorSuccess = false;
    }
    else if(this.data.title === 'investorSuccess'){
      this.investorSuccess = true;
      this.iSuccess = false;
      this.iFailure = false;
      this.wSuccess = false;
      this.nomSuccess = false;
    }
    else if(this.data.title === 'Success'){
      this.iSuccess = true;
      this.iFailure = false;
      this.wSuccess = false;
      this.nomSuccess = false;
      this.investorSuccess = false;
    }
    else if(this.data.title === 'failure'){
      this.iFailure=true;
      this.iSuccess=false;
      this.wSuccess=false;
      this.nomSuccess = false;
      this.investorSuccess = false;
    }
    else if(this.data.title === 'Error'){
      this.error = true;
      this.iFailure = false;
      this.iSuccess = false;
      this.wSuccess = false;
      this.nomSuccess = false;
      this.investorSuccess = false;
    }
    else if(this.data.title === "nomSuccess"){
      this.nomSuccess=true;
      this.error = false;
      this.iFailure = false;
      this.iSuccess = false;
      this.wSuccess = false;
      this.investorSuccess = false;
    }
    else if(this.data.title === "nomFaliure"){
      this.nomFailure=true;
      this.nomSuccess=false;
      this.error = false;
      this.iFailure = false;
      this.iSuccess = false;
      this.wSuccess = false;
      this.investorSuccess = false;
    }
    else if(this.data.title === "accountisNotfetched"){
      this.accountisNotfetched=true;
      this.nomFailure=false;
      this.nomSuccess=false;
      this.error = false;
      this.iFailure = false;
      this.iSuccess = false;
      this.wSuccess = false;
      this.investorSuccess = false;
    }
    else if(this.data.title === "borrowersAllocated"){
      this.borrowersAllocated=true;
      this.accountisNotfetched=false;
      this.nomFailure=false;
      this.nomSuccess=false;
      this.error = false;
      this.iFailure = false;
      this.iSuccess = false;
      this.wSuccess = false;
      this.investorSuccess = false;
    }
    else if(this.data.title === "agreementSend"){
      this.agreementSend=true;
      this.accountisNotfetched=false;
      this.nomFailure=false;
      this.nomSuccess=false;
      this.error = false;
      this.iFailure = false;
      this.iSuccess = false;
      this.wSuccess = false;
      this.investorSuccess = false;
    }
    if(this.data.message == 'sql: no rows in result set'){
      this.data.message = "No data found";
    }
  }
  

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/auth.guard';
import { DashboardGuard } from 'src/app/core/dashboard.guard';
import { BlankComponent } from './blank/blank.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TncComponent } from './tnc/tnc.component';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MarketplaceComponent } from './modules/ifa/marketplace/marketplace.component';

const routes: Routes = [
  { 
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) 
  },
  { 
    path: 'onboarding',
    canActivate: [AuthGuard], 
    loadChildren: () => import('./modules/onbording/onbording.module').then(m => m.OnbordingModule) 
  },
  { 
    path: 'dashboard',
    canActivate: [AuthGuard,DashboardGuard], 
    loadChildren: () => import('./modules/navigation/navigation.module').then(m => m.NavigationModule) 
  },
  { 
    path: 'ifa/dashboard',
    canActivate: [AuthGuard,DashboardGuard], 
    loadChildren: () => import('./modules/ifa/ifa.module').then(m => m.IfaModule) 
  },
  { 
    path: 'ifa/marketplace',
    canActivate: [AuthGuard,DashboardGuard], 
    loadChildren: () => import('./modules/ifa/ifa.module').then(m => m.IfaModule),
    component: MarketplaceComponent, 
  },
  
  { 
    path: 'shared',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/shared/shared.module').then(m => m.SharedModule) 
  },
  {
    path: 'blank', 
    component: BlankComponent, 
  },
  {
    path: 'privacy-policy', 
    component: PrivacyPolicyComponent, 
  },
  {
    path: 'tnc', 
    component: TncComponent, 
  },
  { 
    path: 'reset-password', 
    component: ResetPasswordComponent
  },
  {
    path: 'investment/payment-confirmation',
    component: PaymentConfirmationComponent
  },
  { 
    path: "**", 
    redirectTo: "/auth/login" 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

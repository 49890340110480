import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {
  public BORROWER_CARD = 'api/investor/get-approved-borrower-cards?account_id=';
  public GET_DETAILS = 'api/investor/get-borrower-dashboard-details';
  public INVESTOR = 'api/investor/invest-amount';
  public EMI_SCHEDULAR = 'api/investor/get-investor-emi-scheduler';
  public FUND_ALLOCATION = 'api/funds-allocation?inv_id=';
  private options = {
    headers: new HttpHeaders()
    .set('Content-Type', 'application/json')
    // .set('Strict-Transport-Security', 'max-age=63072000; includeSubDomains; preload')
    // .set('X-Frame-Options', 'SAMEORIGIN')
    // .set('X-XSS-Protection', '1; mode=block')
    // .set('Cache-Control', 'private, no-cache')
    // .set('X-Content-Type-Options', 'nosniff')
    // .set('Content-Security-Policy', 'upgrade-insecure-requests')
    // .set('Referrer-Policy', 'strict-origin-when-cross-origin')
    // .set('Access-Control-Allow-Origin', '*')
	  // .set('Access-Control-Allow-Headers', '*')
    };

  constructor(private http: HttpClient) { }
 
  getBorrowerCard(token: any){
    return this.http.get(environment.borrower_baseUrl + this.BORROWER_CARD + token, this.options);
  }


  getDetails(data: any){
    
    return this.http.post(environment.borrower_baseUrl + this.GET_DETAILS, data, this.options);
	}

  invest(data:any){
    return this.http.post(environment.borrower_baseUrl + this.INVESTOR, data, this.options);
  }

  emiSchedular(data:any){
    return this.http.post(environment.borrower_baseUrl + this.EMI_SCHEDULAR, data,this.options);
  }

  investorDetails(data: any,id : any)
  {
    return this.http.post(environment.base_url + this.FUND_ALLOCATION +id, data, this.options);
  }

}

import { Component, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { Logout } from 'src/app/store/actions/logout.action';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cf-capital';

  // @HostListener('window:beforeunload', ['$event'])
  // onBeforeUnload(event : Event){
  //   this.store.dispatch(new Logout());
  //   document.cookie = `token='';`;
	// 	localStorage.clear();
  // }
  constructor(
		private store : Store) {
  }
}


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  copyrightYear: number = new Date().getFullYear();
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  privacyPolicy() { 
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/privacy-policy'])
    );
    window.open(url, '_blank');
    //window.open('https://creditfaircapital.in/privacy-policy', '_blank');
  }

  terms() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/tnc'])
    );
    window.open(url, '_blank');
     //window.open('https://creditfaircapital.in/terms-conditions', '_blank');
  }

  faq() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/blank'])
    );
    window.open(url, '_blank');
  }

  footerHide() {
    let deviceWidth = window.innerWidth;
    let routeUrl = this.router.url;
    if(deviceWidth <= 768) {
      if(routeUrl != '/auth/login') {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

}

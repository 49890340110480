import { Component,OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Constants } from 'src/app/common/constants/constants';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { CookieService } from 'src/app/services/cookie.service';
import { Logout } from 'src/app/store/actions/logout.action';
import Hashids from "hashids";
import { environment } from 'src/environments/environment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { LoaderComponent } from 'src/app/modules/ifa/loader/loader.component';
import { PopupMessageComponent } from '../popup-message/popup-message.component';
import { MarketplaceService } from 'src/app/modules/ifa/services/marketplace.service';


@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss','./marketplacenew.component.scss']
})


export class MarketplaceComponent {
  filterData: any;
  booleanValue: any = false;
  isopenFilter: boolean = false;
  getBorrowerCardData: any;
  user: any;
  amount!: number;
  showCart: any;
  timeout: any = Constants.POPUP_TIMEOUT;
  count: any;
  productFilter!: string;
  selectedType: any;
  hashdecodeAccountid: any;
  hashInvestid: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    private store: Store,
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
    private marketService: MarketplaceService
  ) { }


  disabled = false;
  max = 42;
  min = 10;
  showTicks = false;
  step = 1;
  thumbLabel = true;
  value = 0;

  maxAmount = 100000;
  minAmount = 0;
  showTicksAmount = true;
  stepAmount = 500;
  thumbLabelAmount = true;
  valueAmount = 0;

  maxMonth = 36;
  minMonth = 1;
  showTicksMonth = false;
  stepMonth = 1;
  thumbLabelMonth = true;
  valueMonth = 0;

  maxIncome = 1000000;
  minIncome = 10000;
  showTicksIncome = false;
  stepIncome = 1;
  thumbLabelIncome = true;
  valueIncome = 0;

  maxFun = 100;
  minFun = 0;
  showTicksFun = false;
  stepFun = 1;
  thumbLabelFun = true;
  valueFun = 0;

  maxAge = 65;
  minAge = 18;
  showTicksAge = false;
  stepAge = 1;
  thumbLabelAge = true;
  valueAge = 0;


  cartData = [
    {
      lno: '23871',
      lname: 'Small Business Loan',
      lamount: '₹ 10,000',
      leftAmount: '₹ 10,000',
      rate: '36.00%',
      tenure: '4',
      time: '5'
    },
    {
      lno: '23871',
      lname: 'Small Business Loan',
      lamount: '₹ 10,000',
      leftAmount: '₹ 10,000',
      rate: '36.00%',
      tenure: '4',
      time: '5'
    },
    {
      lno: '23871',
      lname: 'Small Business Loan',
      lamount: '₹ 10,000',
      leftAmount: '₹ 10,000',
      rate: '36.00%',
      tenure: '4',
      time: '5'
    },
  ];
  loanType = [
    {
      name: 'Active',
    },
    {
      name: 'Funded'
    }
  ];
  purpose = [
    {
      name: 'Travel',
    },
    {
      name: 'Debt Consolidation'
    },
    {
      name: 'Purchase'
    },
    {
      name: 'Medical Bill'
    },
    {
      name: 'Buy Vehicle'
    },
    {
      name: 'Small Business Loan'
    },
    {
      name: 'Home Improvement'
    },
    {
      name: 'Life Events'
    },
    {
      name: 'Education Loan'
    },
    {
      name: 'Other'
    }
  ];
  product = [
    {
      name: 'Education',
    },
    {
      name: 'Home Decor'
    },
    {
      name: 'Green Energy'
    },
    {
      name: 'Healthcare'
    },
  ];
  grade = [
    {
      name: 'AAA',
      value: 'AAA'
    },
    {
      name: 'A',
      value: 'A'
    },
    {
      name: 'B',
      value: 'B'
    },
    {
      name: 'C',
      value: 'C'
    }

  ];
  gender = [
    {
      name: 'Male',
    },
    {
      name: 'Female'
    },
    {
      name: 'Other'
    }
  ];
  emp = [
    {
      name: 'Salaried'
    },
    {
      name: 'Self Employed'
    },
    {
      name: 'Other'
    }
  ];
  housing = [
    {
      name: 'Owned',
    },
    {
      name: 'Rental',
    },
    {
      name: 'Other',
    },
  ];
  productMonth = [
    {
      name: '12'
    },
    {
      name: '24'
    },
    {
      name: '36'
    }
  ]
  selectOption = [
    { value: 'select', viewValue: 'Select' },
    { value: 'intrest-low', viewValue: 'Interest: Low to High' },
    { value: 'intrest-high', viewValue: 'Interest: High to Low' },
    { value: 'income-low', viewValue: 'Income: Low to High' },
    { value: 'income-high', viewValue: 'Income: High to Low' },
    { value: 'tenure-low', viewValue: 'Tenure: Low to High' },
    { value: 'tenure-high', viewValue: 'Tenure: High to Low' }
  ];

  ngOnInit(): void {
    const hashids = new Hashids(environment.investment_accountid_key, 6);
    this.activatedRoute.queryParams.subscribe(params => {
      this.hashdecodeAccountid = params['token'];
      this.hashInvestid = params['investid'];
    })
    const decoded = hashids.decode(this.hashdecodeAccountid);
    const decodedinvestid = hashids.decode(this.hashInvestid);

    console.log(decoded)
    // this.marketplaceService.getBorrowerCard(decoded[0]).subscribe((res: any) => {
    //   this.getBorrowerCardData = res.borrowers;
    //   this.filterData = [...this.getBorrowerCardData];
    //   this.count = this.filterData.length;
    //   if (this.getBorrowerCardData.status == 'FAIL') {

    //     const popup = this.dialog.open(PopupMessageComponent, {
    //       data: {
    //         title: "Error",
    //         message: this.getBorrowerCardData.message,
    //       },
    //     });
    //     popup.afterOpened().subscribe((res) => {
    //       setTimeout(() => {
    //         popup.close();
    //       }, this.timeout);
    //     }
    //     );
    //     this.logout();
    //   }
    // },
    //   (err: any) => {
    //     if (err.error.error.status == 'FAIL') {
    //       const popup = this.dialog.open(PopupMessageComponent, {
    //         data: {
    //           title: 'Error',
    //           message: err.error.error.message,
    //         },
    //       });
    //       popup.afterOpened().subscribe((res) => {
    //         setTimeout(() => {
    //           popup.close();
    //         }, this.timeout);
    //       });
    //     }
    //   }
    // )
    this.getBorrwerdetails(decoded[0],decodedinvestid[0]);
  }

  getBorrwerdetails(accountid: any,investid : any) {
    let payload = {
      "account_id": accountid
    }
    this.marketService.investorDetails(payload,investid).subscribe(
      (res: any) => {
        const loader = this.dialog.open(LoaderComponent);
        if (res.data.total_borrower > 0 && res.data.borrowers != null) {
          loader.close();
          this.getBorrowerCardData = res.data.borrowers;
          this.filterData = [...this.getBorrowerCardData];
          this.count = Math.round(this.filterData.length);
        }
        else {
          loader.close();
        }
      }),
      (err: any) => {
        if(err?.error?.message != 'Borrowers Unavailable'){
          const popup = this.dialog.open(PopupMessageComponent, {
            data: {
              title: "Error",
              message: err.error.message
            }
          })
          popup.afterOpened().subscribe(res => {
            setTimeout(() => {
              popup.close();
            }, this.timeout)
          })
        }
      }
    } 

  changeValue(event: any, type: any) {
    switch (type) {
      case 'intrest_rate':
        if (event.value > 10) {
          this.filterData = [...this.getBorrowerCardData].filter((item: any) => {
            return item.irr <= event.value;
          })
        }
        else {
          this.filterData = this.getBorrowerCardData;
        }
        break;
      case 'loan_amount':
        if (event.value > 0) {
          this.filterData = [...this.getBorrowerCardData].filter((item: any) => {
            return item.loan_amount <= event.value;
          })
        }
        else {
          this.filterData = this.getBorrowerCardData;
        }
        break;
      case 'tenure':
        if (event.value > 1) {
          this.filterData = [...this.getBorrowerCardData].filter((item: any) => {
            return item.net_tenure <= event.value;
          })
        }
        else {
          this.filterData = this.getBorrowerCardData;
        }
        break;
      case 'grade':
        if (event.value?.length > 0) {
          this.filterData = [...this.getBorrowerCardData].filter((item: any) => {
            // return event.value.includes(item.net_tenure)
            return item.net_tenure <= event.value;
          })
        }
        else {
          this.filterData = this.getBorrowerCardData;
        }
        break;
      default: this.filterData = this.getBorrowerCardData;
    }
    // this.user = event.value;
    // this.amount = event.value * 9;
    // console.log(event.value, 'event.value');

    // console.log(this.getBorrowerCardData, 'this.getBorrowerCardData');
  }



  onRadioChange(value: any) {
    this.selectedType = value;
    this.changeValue({ value: Number(this.selectedType.value) }, 'tenure');
  }
  clear() {
    this.filterData = this.getBorrowerCardData;
  }

  openDetails(id: any) {
    //this.router.navigateByUrl(`p2pdashboard/borrower-details/${id}?token=${this.hashdecodeAccountid}`);
  }
  openFilter() {
    this.isopenFilter = true;
  }
  close() {
    this.isopenFilter = false;
  }
  back() {
    this.router.navigateByUrl('investor-dashboard/my-dashboard');
  }

  logout() {
    let payload = {

    }
    this.authService.logout(payload).subscribe((res: any) => {
      this.store.dispatch(new Logout());
      localStorage.clear();

      this.cookieService.deleteCookie('token');
      this.router.navigateByUrl('/auth/login');
    })
  }

  radioButtonGroupChange(data: MatRadioChange) {
    console.log(data.value);

  }


  gradeFilter(data: MatCheckboxChange, item: any) {
    let getValue = [];
    if (data.checked) {
      getValue.push(item.value);
    }

    this.changeValue({ value: getValue }, 'grade');
  }

  sort(viewValue: any) {
    if (viewValue == 'Interest: Low to High') {
      this.filterData.sort((a: any, b: any) => a.interest_rate > b.interest_rate ? 1 : a.interest_rate < b.interest_rate ? -1 : 0)
    } else if (viewValue == 'Interest: High to Low') {
      this.filterData.sort((a: any, b: any) => a.interest_rate < b.interest_rate ? 1 : a.interest_rate > b.interest_rate ? -1 : 0)
    } else if (viewValue == 'Income: Low to High') {
      this.filterData.sort((a: any, b: any) => a.amount_left > b.amount_left ? 1 : a.amount_left < b.amount_left ? -1 : 0)
    } else if (viewValue == 'Income: High to Low') {
      this.filterData.sort((a: any, b: any) => a.amount_left < b.amount_left ? 1 : a.amount_left > b.amount_left ? -1 : 0)
    } else if (viewValue == 'Tenure: Low to High') {
      this.filterData.sort((a: any, b: any) => a.tenure > b.tenure ? 1 : a.tenure < b.tenure ? -1 : 0)
    } else if (viewValue == 'Tenure: High to Low') {
      this.filterData.sort((a: any, b: any) => a.tenure < b.tenure ? 1 : a.tenure > b.tenure ? -1 : 0)
    }
  }
}

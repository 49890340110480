<div class="pd10">  
    <div class="header">
        <div class="col-md-6" style="padding-left: 30px;">
            <div class="logo-width">
                <img src="../../assets/images/logo_update.png" />
            </div>        
        </div>
        <div class="col-md-6 login-acc voilet-text" [hidden]=hideElement>
            <span style="font-size: 15px; margin-right: 10px;" class="fw-500" [hidden]="hideOnboard">{{username}}</span>
            <div *ngIf="(!isOnboarding && !isLogin && hideInvestorButton)">
                <div class="f-16 white-text ps-4 fw-600 referral-link" style="background-color: deepskyblue;height: 35px;width: 155px;">Referral Link: <mat-icon matTooltip={{copyTooltip}} (click)="copy_file()"
                class="cPointer">file_copy</mat-icon>
                </div>
            </div>
            <!-- <div  [hidden]="hideInvestorButton">
                <button mat-raised-button color="primary" class="caps" (click)="addInvestor()">Add Investor</button>
            </div>  -->
            <div *ngIf="(!isOnboarding && !isLogin && !hideInvestNowButton)">
                <button mat-raised-button color="primary" class="caps" (click)="investNow()">Invest Now</button>
            </div>        
            <div *ngIf="(!isOnboarding && !isLogin && !hideGoToDashboardButton)">
                <button mat-raised-button color="primary" class="caps" (click)="ifaDashboard()">Dashboard</button>
            </div>     
            <div *ngIf="(!isOnboarding && !isLogin && hideInvestorButton)">
                <button mat-raised-button color="primary" class="caps" (click)="investorOnboard()">Add Investor</button>
            </div>  
            <span class="ml-10" *ngIf="!isLogin">
                <mat-icon (click)="logout()" style="cursor: pointer;">power_settings_new</mat-icon>
            </span>
        </div>
    </div>
</div>  